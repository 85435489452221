/*
 * Backport https://github.com/staskobzar/vue-audio-visual/blob/master/src/components/AvBars.js
 */

import React, { Component } from "react";
import Proptypes from "prop-types";

const AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false;

class AvBars extends Component {
  static propTypes = {
    analyser: Proptypes.instanceOf(AnalyserNode).isRequired,
    audioCtx: Proptypes.instanceOf(AudioContext).isRequired,
    barWidth: Proptypes.number,
    barSpace: Proptypes.number,
    barColor: Proptypes.oneOfType([Proptypes.array, Proptypes.string]),
    capsHeight: Proptypes.number,
    capsDropSpeed: Proptypes.number,
    capsColor: Proptypes.string,
    brickHeight: Proptypes.number,
    brickSpace: Proptypes.number,
    symmetric: Proptypes.bool,
    fftSize: Proptypes.number,
  };

  static defaultProps = {
    // Width of the bar in pixels.
    barWidth: 5,
    // Space between bars.
    barSpace: 1,
    // Bar fill color. Can be string RGB color or canvas gradients array.
    barColor: "#0A0AFF",
    // Create caps on bars with given height in pixels.
    capsHeight: 0,
    // Caps drop down animation speed.
    capsDropSpeed: 0.9,
    // Caps rectangles RGB color.
    capsColor: "#A0A0FF",
    // Draw bar as bricks with set height.
    brickHeight: 0,
    // Space between bricks
    brickSpace: 1,
    // Draw bars symmetric to canvas vertical center
    symmetric: false,
    /*
     * Represents the window size in samples that is used when performing
     * a Fast Fourier Transform (FFT) to get frequency domain data.
     * Must be power of 2 between 2^5 and 2^15

     */
    fftSize: 1024,
  };

  constructor(props) {
    super(props);

    //props
    this.barWidth = props.barWidth;
    this.barSpace = props.barSpace;
    this.barColor = props.barColor;
    this.capsHeight = props.capsHeight;
    this.capsDropSpeed = props.capsDropSpeed;
    this.capsColor = props.capsColor;
    this.fftSize = props.fftSize;
    this.symmetric = props.symmetric;
    this.brickSpace = props.brickSpace;
    this.brickHeight = props.brickHeight;

    this.analyser = props.analyser;
    this.audioCtx = props.audioCtx;

    this.caps = Array.apply(null, Array(this.fftSize / 2)).map(() => 0);
  }

  componentDidMount() {
    this.canvWidth = this.canvas.width;
    this.canvHeight = this.canvas.height;
    this.ctx = this.canvas.getContext("2d");
    this.mainLoop();
  }

  componentWillUnmount() {
    if (this.animId) {
      cancelAnimationFrame(this.animId);
      this.animId = undefined;
    }
  }

  /**
   * Main loop. Draws visualization.
   */
  // @autobind
  mainLoop = () => {
    const frqBits = this.analyser.frequencyBinCount;
    const data = new Uint8Array(frqBits);

    const barWidth =
      this.barWidth >= this.canvWidth ? this.canvWidth : this.barWidth;
    const step = Math.round(
      ((barWidth + this.barSpace) / frqBits) * this.canvWidth
    );
    const barFill = Array.isArray(this.barColor)
      ? this.fillGradient(this.barColor)
      : this.barColor;
    let x = 0;

    this.analyser.getByteFrequencyData(data);
    this._fillCanvasBG();

    this.ctx.fillStyle = barFill; // Color of the bars

    data.forEach((_, index) => {
      if (index % step) return;
      const bits = Math.round(
        data.slice(index, index + step).reduce((v, t) => t + v, 0) / step
      );
      const barHeight = (bits / 255) * this.canvHeight;
      if (this.capsHeight) {
        this._drawCap(index, barWidth, x, bits);
      }
      this._drawBar(barWidth, barHeight, x);
      x += barWidth + this.barSpace;
    });
    this.animId = requestAnimationFrame(this.mainLoop);
  };

  /**
   * Canvas background fill
   * @private
   */
  _fillCanvasBG() {
    const w = this.canvWidth;
    const h = this.canvHeight;
    this.ctx.clearRect(0, 0, w, h);
    if (this.canvFillColor) {
      this.ctx.fillStyle = Array.isArray(this.canvFillColor)
        ? this.fillGradient(this.canvFillColor)
        : this.canvFillColor;
      this.ctx.fillRect(0, 0, w, h);
    }
  }

  /**
   * Draw bar. Solid bar or brick bar.
   * @private
   */
  _drawBar(barWidth, barHeight, barX) {
    if (this.brickHeight) {
      this._drawBrickBar(barWidth, barHeight, barX);
    } else {
      this.ctx.fillRect(
        barX,
        this.canvHeight - barHeight - this._symAlign(barHeight),
        barWidth,
        barHeight
      );
    }
  }

  /**
   * Draw bricks bar.
   * @private
   */
  _drawBrickBar(barWidth, barHeight, barX) {
    for (let b = 0; b < barHeight; b += this.brickHeight + this.brickSpace) {
      this.ctx.fillRect(
        barX,
        this.canvHeight - barHeight + b - this._symAlign(barHeight),
        barWidth,
        this.brickHeight
      );
    }
  }

  /**
   * Draw cap for each bar and animate caps falling down.
   * @private
   */
  _drawCap(index, barwidth, barX, barY) {
    const cap =
      this.caps[index] <= barY ? barY : this.caps[index] - this.capsDropSpeed;
    const y = (cap / 255.0) * this.canvHeight;
    const capY = this.canvHeight - y - this.capsHeight - this._symAlign(y);
    this.ctx.fillStyle = this.capsColor;
    this.ctx.fillRect(barX, capY, barwidth, this.capsHeight);
    if (this.symmetric) {
      this.ctx.fillRect(
        barX,
        this.canvHeight - capY - this.capsHeight,
        barwidth,
        this.capsHeight
      );
    }
    this.caps[index] = cap;
  }
  /**
   * Shift for symmetric alignment
   * @private
   */
  _symAlign(barHeight) {
    return this.symmetric ? (this.canvHeight - barHeight) / 2 : 0;
  }

  render() {
    let style = {
      width: "30px",
      height: "17px",
    };
    return (
      <canvas
        style={style}
        className="AvBars-canvas"
        ref={(canvas) => {
          this.canvas = canvas;
        }}
      ></canvas>
    );
  }
}

export default AvBars;
