import React, { Component } from "react";
import Proptypes from "prop-types";

import "./ChordIdentification.css";
import FontAwesome from "react-fontawesome";

import Soundfont from "soundfont-player";
import AvBars from "./AvBars.js";
import HallOfFame from "./HallOfFame.js";

const AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false;

const AUTO_PLAY_TIMEOUT = 3; // Autoplay after 4s
const PARTY_LEN = 15;

class ChordIdentification extends Component {
  static propTypes = {
    pitchArrangement: Proptypes.string.isRequired,
    choices: Proptypes.array.isRequired,
    exitHandler: Proptypes.func.isRequired,
  };

  toString(chord) {
    switch (chord) {
      case "min":
        return "Minor";
      case "maj":
        return "Major";
      default:
        return null;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currChord: this.generateChord(),
      currRound: 1,
      scores: Array(PARTY_LEN).fill(-1),
      answer: null,
      // application state
      isWaitingAnswer: true,
      isLoading: true,
      answerIsCorrect: null,
      countDown: 0,
    };

    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();

    this.instrument = null;
    Soundfont.instrument(this.audioContext, "acoustic_guitar_steel").then(
      (instrument) => {
        this.instrument = instrument;
        this.instrument.connect(this.analyser);
        this.analyser.connect(this.audioContext.destination);
      }
    );
  }

  componentDidMount() {
    let intervalId = setInterval(() => {
      if (this.instrument !== null) {
        this.setState({ isLoading: false });
        this.playCurrentChord();
        clearInterval(intervalId);
      }
    }, 500);
  }

  componentWillUnmount() {
    this.audioContext.close();
  }

  componentDidUpdate() {
    if (this.state.isWaitingAnswer && !this._isPartyOver()) {
      this.playCurrentChord();
    }
  }

  playCurrentChord() {
    switch (this.state.currChord) {
      case "maj":
        this.instrument.play(60);
        this.instrument.play(64);
        this.instrument.play(67);
        break;
      case "min":
        this.instrument.play(60);
        this.instrument.play(63);
        this.instrument.play(67);
        break;
      default:
        break;
    }
  }

  generateChord() {
    return this.props.choices[
      Math.floor(Math.random() * this.props.choices.length)
    ];
  }

  handleAnswer(choice) {
    let scores = [...this.state.scores];

    switch (this.state.currChord) {
      case "maj":
        this.setState({ answer: "1, 3, 5" });
        break;
      case "min":
        this.setState({ answer: "1, ♭3, 5" });
        break;
      default:
    }

    if (choice === this.state.currChord) {
      scores[this.state.currRound - 1] = 1;
      this.setState({ answerIsCorrect: true, scores: scores });
    } else {
      scores[this.state.currRound - 1] = 0;
      this.setState({ answerIsCorrect: false, scores: scores });
    }

    this.setState({ isWaitingAnswer: false });

    this._startCountDown();
  }

  handlePlayButton() {
    if (this.nextQuestionTimeoutId) {
      clearInterval(this.nextQuestionTimeoutId);
      this.nextQuestionTimeoutId = null;
    }

    this.playCurrentChord();

    if (this.state.isWaitingAnswer === false) {
      this._startCountDown();
    }
  }

  handleNextButton() {
    if (this.nextQuestionTimeoutId) {
      clearInterval(this.nextQuestionTimeoutId);
      this.nextQuestionTimeoutId = null;
    }
    this.generateNextQuestion();
  }

  generateNextQuestion() {
    this.setState((prevState) => ({
      currChord: this.generateChord(),
      currRound: prevState.currRound + 1,
      isWaitingAnswer: true,
      answerIsCorrect: null,
    }));
  }

  /* Pass automatically to next question after 3s */
  _startCountDown() {
    this.setState({ countDown: AUTO_PLAY_TIMEOUT });

    this.nextQuestionTimeoutId = setInterval(() => {
      if (this.state.countDown) {
        this.setState((prevState) => ({
          countDown: prevState.countDown - 1,
        }));
      } else {
        clearInterval(this.nextQuestionTimeoutId);
        this.nextQuestionTimeoutId = null;
        this.generateNextQuestion();
      }
    }, 1000);
  }

  _isPartyOver() {
    return this.state.currRound > PARTY_LEN;
  }

  /* Replay game */
  // @autobind
  initState = () => {
    this.setState({
      currChord: this.generateChord(),
      currRound: 1,
      scores: Array(PARTY_LEN).fill(-1),
      isWaitingAnswer: true,
      answerIsCorrect: null,
    });
  };

  calculateScore() {
    let total = this.state.scores.reduce((a, b) => a + b, 0);
    return (total / PARTY_LEN) * 100;
  }

  render() {
    let { choices } = this.props;

    const leaveExercice = (
      <span onClick={() => this.props.exitHandler()}>
        <FontAwesome name="times-circle" />
      </span>
    );

    const scoreProgression = this.state.scores.map((score, index) => (
      <span
        key={index}
        className={`ChordIdentification-progression ${
          score === 1 ? " correct" : ""
        } ${score === 0 ? " incorrect" : ""}`}
      ></span>
    ));

    const controlButtons = (
      <div>
        <button
          className="ChordIdentification-controlButtons playButton"
          onClick={() => this.handlePlayButton()}
          disabled={this.state.isLoading}
        >
          <span>
            <FontAwesome name="repeat" /> Repeat
          </span>
          {"  "}
          {this.state.isLoading === true ? (
            <span>
              <FontAwesome
                style={{ color: "white" }}
                className="fa-spin"
                name="spinner"
              />
            </span>
          ) : (
            <AvBars
              analyser={this.analyser}
              audioCtx={this.audioContext}
              barColor="#00CCFF"
              symmetric={true}
            />
          )}
        </button>
        <button
          className="ChordIdentification-controlButtons"
          disabled={this.state.isLoading || this.state.isWaitingAnswer}
          onClick={() => this.handleNextButton()}
        >
          <span>
            <FontAwesome name="arrow-right" /> Next{" "}
            {this.state.countDown >= 0 ? `(${this.state.countDown})` : ""}
          </span>
        </button>
      </div>
    );

    const answerButtons = choices.map((choice) => (
      <button
        className="ChordIdentification-choice-button"
        key={choice}
        onClick={() => this.handleAnswer(choice)}
        disabled={this.state.isLoading || !this.state.isWaitingAnswer}
      >
        {this.toString(choice)}
      </button>
    ));

    return this._isPartyOver() ? (
      <div className="ChordIdentification-content">
        <HallOfFame
          score={this.calculateScore()}
          exitHandler={this.props.exitHandler}
          newGameHandler={this.initState}
        />
      </div>
    ) : (
      <div className="ChordIdentification-content">
        <div className="ChordIdentification-close">{leaveExercice}</div>
        <div>{scoreProgression}</div>
        <div
          className={`AnswerPanel ${
            this.state.answerIsCorrect === true ? " correct" : ""
          } ${this.state.answerIsCorrect === false ? " incorrect" : ""}`}
        >
          {this.state.isWaitingAnswer ? "?" : this.state.answer}
        </div>
        <div>{controlButtons} </div>
        <div>{answerButtons}</div>
      </div>
    );
  }
}

export default ChordIdentification;
